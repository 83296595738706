import React, { useState, useEffect } from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import InfoInput from './InfoInput'

export default ({
  value = '',
  label = null,
  checked = false,
  disabled = false,
  onChange = () => void 0,
  required,
  styles = { container: '' },
  responsive,
  centered,
  inline,
  labelStyle,
  name,
  labelRight,
  initialValue,
  info,
  className = '',
}) => {
  const [check, setCheck] = useState(disabled ? false : checked)

  useEffect(() => {
    setCheck(disabled ? false : checked)
  }, [disabled])

  const handleEffect = event => {
    if (!disabled) {
      onChange(event, {
        checked: !check,
        label,
        value:   label,
      })
      setCheck(!check)
    }
  }

  const getLabel = () => {
    if (typeof label === 'string') {
      return <label style={labelStyle} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
        {label}
      </label>
    } else {
      return label
    }
  }

  return <Form.Field
    required={required}
    className={styles.container}
    style={responsive ? centered ? { display: 'flex', justifyContent: 'center' } : { display: 'flex' } : {}}
    inline={inline} >
    {label && !labelRight && getLabel()}
    <Checkbox
      className={className}
      value={value}
      disabled={disabled}
      checked={check}
      label={labelRight ? label : ''}
      onClick={handleEffect}
    />
    {info && <InfoInput info={info} />}
  </Form.Field>
}
