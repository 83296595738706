/* eslint-disable import/no-anonymous-default-export */
import jwtDecode from 'jwt-decode'
import React from 'react'
import { createBrowserHistory } from 'history/cjs/history.min'
import { pathToRegexp } from 'path-to-regexp'

const headers = {
  'Access-Control-Request-Headers': '*',
  'Access-Control-Request-Method': '*',
  'Access-Control-Allow-Origin': '*',
}

const jsonHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

export const getHeaders = (multiform = false) => {
  return ({
    ...headers,
    ...(multiform ? {} : jsonHeaders),
    'x-refresh-token': localStorage.getItem('refreshToken'), // await SecureStore.getItemAsync('refreshToken', {}),
    'x-access-token': localStorage.getItem('token'), // await SecureStore.getItemAsync('userToken', {}),
    'x-language': localStorage.getItem('language'),
  })
}

export const catchGesture = ({ status }, history) =>
  status === 403 && history.push('')

export const isObject = elem => typeof elem === 'object' && elem !== null

export const isTokenRegenerate = async (res, apiRoute, params) => {
  const body = await res.json() || {}
  res.message = body.message
  if (isObject(res) && res.status) {
    if (body.message === 'regenerateTokens') {
      saveTokens(body.tokens, () => { })
      return fetchApi(apiRoute, params)
    } else if (body.message === 'disconnect') {
      logOut()
    } else if (body.message === 'forbidden') {
      return history.push('/')
    }
  }
  return Promise.reject(res)
}

export const responseToJson = (res, apiRoute, params) => {
  if (res.ok) {
    return res.json()
  } else {
    return isTokenRegenerate(res, apiRoute, params)
  }
}

export const apiUrl = process.env.REACT_APP_API_URL
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL
export const fetchApi = async (route, init = null) => {
  const apiRoute = apiUrl + route
  const params = !init ? { headers: getHeaders() } : {
    method: init && init.body ? 'POST' : 'get',
    ...init,
    mode: 'cors',
    headers: { ...getHeaders(init.multiform), ...init.headers },
    body: init.multiform ? init.body : JSON.stringify(init.body),
  }
  return fetch(apiRoute, params)
    .then(res =>
      res.status !== 204
        ? responseToJson(res, route, !init ? null : params)
        : null
    )
}

export const setLoggedInCookie = (value, expiresIn = Date.now()) =>
  (document.cookie = `loggedIn=${value}; expires=${expiresIn}; path=/`)

export const isLoggedIn = () =>
  document.cookie && document.cookie.split(';').reduce((cookies, str) => {
    const [key, value] = str.split('=').map(s => s.trim())
    cookies[key] = value
    return cookies
  }, {}).loggedIn === '1'

export const saveTokens = ({ token, refreshToken }, dispatch) => {
  const user = jwtDecode(token.token)
  if (user) {
    setLoggedInCookie(1, token.expiresIn)
    localStorage.setItem('token', token.token)
    localStorage.setItem('tokenExpiration', refreshToken.expiresIn)
    localStorage.setItem('refreshToken', refreshToken.token)
    localStorage.setItem('refreshTokenExpiration', token.expiresIn)
    localStorage.setItem('profile', JSON.stringify(user))
    return token
  } else {
    throw new Error('Token is no-readeable.')
  }
}

export const isNull = value => value === null || value === undefined || value === 'NULL' || value === 'undefined' || value === 'null'

export const logOut = () => {
  setLoggedInCookie(0)
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('refreshTokenExpiration')
    localStorage.removeItem('profile')
    return history.push('/')
}

export const getImage = imageId => {
  return fetchApi('/image/' + imageId)
}

export const updateLanguage = language => {
  return fetchApi('/admin/language', {
    method: 'PATCH',
    body:   { language: language },
  })
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function unCapitalizeFirstLetter (string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export function round (value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const roundUpToNextTen = value => value % 10 === 0 ? value : ((Math.floor(value / 10)) + 1) * 10
export const roundUpToLastTen = value => value % 10 === 0 ? value : ((Math.floor(value / 10))) * 10
export const strToLineBreak = str => <div>
  {str
    .split('.')
    .filter(e => e)
    .map(sentence => sentence + '.')
    .map((sentence, index) => (
      <div key={index}>{sentence}</div>
    ))}
</div>

export const regenerateTokens = dispatch => fetchApi('/admin/regenerateTokens')
  .then(tokens => saveTokens(tokens, dispatch))
  .catch(e => {
    logOut()
    process.env.NODE_ENV !== 'production' && console.error(e.message)
  })
export const history = createBrowserHistory()

export const getUser = () => {
  const data = JSON.parse(localStorage.getItem('profile'))
  return data || {}
}

export const getParentsPaths = () => {
  const paths = {
    home:       '/',
    adminHome:  '/admin/',
    profile:    '/profile',
    support:    '/support',
  }
  return paths
}

export const getLoginPathsIndexes = () => {
  const parentsPaths = getParentsPaths()

  return ({
    home: {
      href:   '/',
      parent: parentsPaths.home,
      get regex () {
        return pathToRegexp(this.href)
      },
    },
    page: {
      href:   '/company/:companyId',
      parent: parentsPaths.home,
      get regex () {
        return pathToRegexp(this.href)
      },
    },
    adminHome: {
      href:   '/admin/',
      parent: parentsPaths.adminHome,
      get regex () {
        return pathToRegexp(this.href)
      },
    },
    profile: {
      href:   '/profile',
      parent: parentsPaths.profile,
      get regex () { return pathToRegexp(this.href) },
    },
    profileUpdate: {
      href:   '/profile/update',
      parent: parentsPaths.profile,
      get regex () { return pathToRegexp(this.href) },
    },
    support: {
      href:   '/support',
      parent: parentsPaths.support,
      get regex () { return pathToRegexp(this.href) },
    },
    homePrincipal: {
      href:   '/home',
      parent: parentsPaths.home,
      get regex () { return pathToRegexp(this.href) },
    },
    adminHomePrincipal: {
      href:   '/admin/home',
      parent: parentsPaths.adminHome,
      get regex () { return pathToRegexp(this.href) },
    },
    subscription: {
      href:   '/subscription/payment/:plan',
      parent: parentsPaths.plan,
      get regex () { return pathToRegexp(this.href) },
    },
  })
}

export const getloginPaths = () => {
  const paths = getLoginPathsIndexes()
  const obj = {}
  Object.keys(paths).map(key => (
    obj[key] = paths[key].href
  ))
  return obj
}

export const userRoles = {
  USER: 0,
  ADMIN: 1,
  SUPERADMIN: 2,
  GUEST: 3,
}

export const userRoleReadable = userRole => {
  switch (userRole) {
    case userRoles.USER:
      return 'Standart'
    case userRoles.ADMIN:
      return 'Admin'
    case userRoles.SUPERADMIN:
      return 'Super Admin'
    case userRoles.GUEST:
      return 'Invité'
    default:
      return 'Standart'
  }
}


export default {
  isLoggedIn,
  fetchApi,
  responseToJson,
  setLoggedInCookie,
  logOut,
  saveTokens,
  catchGesture,
  getImage,
  apiUrl,
  capitalizeFirstLetter,
  unCapitalizeFirstLetter,
  round,
  roundUpToNextTen,
  roundUpToLastTen,
  strToLineBreak,
  regenerateTokens,
  getUser,
  isObject,
  getParentsPaths,
  getLoginPathsIndexes,
  getloginPaths,
  isNull,
  userRoles,
  userRoleReadable
}
