import React from 'react'
import {
  TextArea, Form,
} from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'

const InlineTextArea = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  maxLength = null,
  label,
  legend = null,
  required,
  error,
  silentRequired = false,
  initialValue,
  handleEffect,
  requiredLang,
  info,
}) => {
  return (
    <Form.Field
      required={required}
    >
      {label && <div >
        <label className="conform-label" htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label>
        {required && error && !silentRequired &&
          <Label
            style={{ display: 'inline-table' }}
            display={requiredLang}
            pointing={'left'}
            color={'red'} />
        }
      </div>

      }

      <div className="flex">
        <TextArea
          className="description-input"
          id={name + (initialValue ? '-' + initialValue : '')}
          name={name}
          value={value || ''}
          placeholder={placeholder}
          onChange={handleEffect}
          onBlur={handleEffect}
          maxLength={maxLength} />
        {legend && <p className="legend-input">{legend}</p>}
        {info && <InfoInput info={info} />}
      </div>
    </Form.Field>
  )
}

export default InlineTextArea
