/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState } from 'react'
import { createUser, createAdmin } from './helpers'
import UnLogGrid from '../../../components/UnLogGrid'
import { Store } from '../../../store/store'
import ModalComponent from '../../../components/Modal'

export default ({ history, match }) => {
  const { state } = useContext(Store)
  const [modal, setModal] = useState(false)
  const [error, setError] = useState('')
  const [googleSignUp, setGoogleSignUp] = useState(false)
  const {
    leftSide: {
      mail: mailLang,
      password: passwordLang,
      validUp:validUpLang,
      signupModalTitle: signupModalTitleLang,
      signupModalTextSuccess: signupModalTextSuccessLang,
      signupModalButtonSuccess: signupModalButtonSuccessLang,
      signupModalButtonError: signupModalButtonErrorLang,
      googleAccountText:googleAccountTextLang,
      errorTitle: errorTitleLang,
    },
  } = state.language.login

  const inputs = [
    {
      type:           'text',
      name:           'email',
      placeholder:    mailLang,
      required:       true,
      silentRequired: true,
      fluid:          true,
    },
    {
      type:           'password',
      name:           'password',
      placeholder:    passwordLang,
      required:       true,
      silentRequired: true,
      fluid:          true,
      legend:         'minimum 5 chars',
      signIn:         true,
    },
  ]

  const sendForm = async values => {
    try {
      await createAdmin(values)
    } catch (e) {
      setError(e.message)
    }
    setModal(true)
  }

  const goToLogin = () => history.push('/login')
  const closeErrorModal = () => {
    setError('')
    setModal(false)
  }
  return (
    <React.Fragment>
      <UnLogGrid
        inputs={inputs}
        sendForm={sendForm}
        navigateHandle={goToLogin}
        page="signup"
        submitMessage={validUpLang}
      />
      <ModalComponent
        onClose={error !== '' ? closeErrorModal : goToLogin}
        open={modal}
        title={error !== '' ? errorTitleLang :  signupModalTitleLang}
        description={error !== '' ? error : googleSignUp ? googleAccountTextLang : signupModalTextSuccessLang }
        onClick={error !== '' ? closeErrorModal : goToLogin}
        button={error !== '' ? signupModalButtonErrorLang : signupModalButtonSuccessLang}

      />
    </React.Fragment>
  )
}
