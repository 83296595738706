import React from 'react'
import { Icon } from 'semantic-ui-react'

const InfoInput = ({
  info = '',
  infoClassName = '',
  className = '',
  size = 'large',
}) => {
  return (
    <div className={'question-form-container ' + className}>
      <Icon size={size} name="question circle outline" />
      <p className={'hidden info ' + infoClassName}>{info}</p>
    </div>
  )
}

export default InfoInput
