import React, {
  useContext, useEffect, useState,
} from 'react'
import { Store } from '../../../store/store'
import HomeComponent from './Home'
import RightSideLoading from '../../../components/RightSideLoading'
import { getCompanies, deleteCompanyAPI, createCompany } from './helpers'

const Home = ({ history }) => {
  const { state, dispatch } = useContext(Store)
  const [companies, setCompanies] = useState([])
  const { language: {
    home: {
      email: emailLang,
      companyName: companyNameLang,
      siret: siretLang,
    }
  } } = state
  const getAllCompanies = async () => 
    setCompanies(await getCompanies())

  const deleteCompany = async (id) => {
    await deleteCompanyAPI(id)
    getAllCompanies()
  }

  const inputs = [{
    label: emailLang,
    type: 'email',
    name: 'email',
    placeholder: 'example@example.com',
    required: true,
    silentRequired: true,
  },
  {
    label: companyNameLang,
    type: 'text',
    name: 'name',
    placeholder: 'Channel',
    required: true,
    silentRequired: true,
  },
  {
    label: siretLang,
    type: 'text',
    name: 'siret',
    placeholder: '12FN49H3094J',
    required: true,
    silentRequired: true,
  }
  ]

  const sendForm = async values => {
    try {
      await createCompany(values)
      getAllCompanies()
    } catch (e) {
      console.log('e :>> ', e);
    }
  }

  useEffect(() => {
    getAllCompanies()
    dispatch({ type: 'LOADING_OFF' })
  }, [])

  return (
    <RightSideLoading>
      <HomeComponent
        inputs={inputs}
        history={history}
        language={state.language.home}
        companies={companies}
        deleteCompany={deleteCompany}
        sendForm={sendForm}
      />
    </RightSideLoading>)
}

export default Home
