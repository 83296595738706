import React, {
   useEffect, useState,
} from 'react'
import Loader from '../../../components/Loader'

const GoogleConnect = ({
  history,
  match,
}) => {
  const [loading, setLoading] = useState(true)


  useEffect(() => {
        history.push('')
  }, [])

  return (<div>
    {loading ? <Loader/> :
    <div>
    </div>
    }
  </div>)
}

export default GoogleConnect
