export const FR = {
  answer:       'réponses',
  survey:       'Étude',
  step:         'Étape',
  on:           'sur',
  unavailable:  'Pas de retour en arrière à cette étape',
  previousPage: 'Retourner à la page précédente',
}

export const EN = {
  answer:       'answers',
  survey:       'Survey',
  step:         'Step',
  on:           'on',
  unavailable:  'Go back isn\'t possible at this step',
  previousPage: 'Back to previous page',
}

export default {
  fr: FR,
  us: EN,
}
