import React from 'react'
import LanguageSelection from '../../../components/LanguageSelection'
import {
  Segment, Button, List, Icon,
} from 'semantic-ui-react'
import { plans } from '../../../helpers'

const HeaderComponent = ({
  readAndDeleteNotif,
  readNotification,
  notifications = [],
  setRedirect,
  logOut,
  goHome,
  OpenIcon,
  close,
  isOpen,
  dialogType,
  isLogin,
  language:{
    logOut:logOutLang,
    profile:profileLang,
  },
  pro,
  changeLanguage,
}) => {
  return (
    <div className="header-container"
      onMouseLeave={close}
    >
      <div className="header-icon-container">
        {isLogin ? <OpenIcon name={pro > plans.FREEMIUM ? 'user secret' : 'user'} /> :
        <LanguageSelection />}
        {/* {isLogin && <OpenIcon
          name={`bell${notifications.length > 0 ? '' : ' outline'}`}
        />} */}
      </div>

      {isOpen && <Segment className=" bg-my-purple ">
        <div className="close-dialog"
          onClick={close}>
          <Icon name="close" />
        </div>
        {dialogType === 'notifs' ? <List>
          {notifications.length > 0 ? notifications.map(({
            title, message, link, id,
          }, index) =>
            <List.Item className="notification" key={index} as={link && 'a'} onClick={() => {
              readAndDeleteNotif(id)
              link && setRedirect(link)
            }}>
              <List.Icon size="large" className="icon-notification" name='bell' color="red" />
              <List.Content>
                <List.Header as="h4" >{title}</List.Header>
                <List.Description className="description-notification">
                  {message}
                  {/* <List.Button onClick={}>Go to page</List.Button> */}
                </List.Description>
              </List.Content>
            </List.Item>
          ) : <p>No new notifications.</p>}

        </List> : <div>
          <div>
            <LanguageSelection changeLanguage={changeLanguage} />
          </div>
          <div style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Button className="bg-yellow-flash" onClick={() => setRedirect('/profile')}>{profileLang}</Button>
          </div>

          <div >
            <Button className="bg-red navbar-button-logout" onClick={logOut}>{logOutLang}</Button>
          </div>
        </div>}
      </Segment>}
    </div>
  )
}

export default HeaderComponent
