import { fetchApi } from '../../helpers'

const isUniqueNotification = (notif, index, arr) => {
  for (let i = index - 1; i >= 0; i--) {
    if (JSON.stringify(notif) === JSON.stringify(arr[i])) return false
  }
  return true
}

export const getNotifications = (userId, notifications, dispatch) =>
  fetchApi('/notifications/' + userId)
    .then(persistantNotifications => {
      const allNotifications = notifications.concat(persistantNotifications)
      const uniqueNoitifications = allNotifications.filter(isUniqueNotification)
      dispatch({
        type:    'INIT_NOTIFICATIONS',
        payload: uniqueNoitifications,
      })
    })

export const readNotification = (notificationId, dispatch) =>
  fetchApi('/notification/read/' + notificationId, {
    method: 'PATCH',
  }).then(() => dispatch && dispatch({
    type:    'REMOVE_NOTIFICATION',
    payload: notificationId,
  }))

export default {
  getNotifications,
}
