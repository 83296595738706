import React from 'react'

const MessageLinkeable = ({
  style,
  onClick,
  message,
}) => {
  return (
    <div style={style} className="message-linkeable" onClick={onClick} >
      {message}
    </div>
  )
}

export default MessageLinkeable
