import React, { useContext, useEffect } from 'react'
import { Store } from '../../store/store'
import MenuComponent from './Menu'
import { getItemsMenu, getMenuIndex } from './helpers'

const Menu = ({
  history,
}) => {
  const { state } = useContext(Store)
  const { menuItems, lastItem } = getItemsMenu(state)
  const { state: { indexMenu }, dispatch } = useContext(Store)

  useEffect(() => {
    const { pathname } = history.location
    const menuIndex = getMenuIndex(pathname)
    dispatch({ type: 'UPDATE_INDEX_MENU', payload: menuIndex })
  }, [history.location, dispatch])

  return (
    <MenuComponent
      menuItems={menuItems}
      lastItem={lastItem}
      indexMenu={indexMenu}
    />
  )
}

export default Menu
