/* eslint-disable import/no-anonymous-default-export */
import formLanguage from './form.language'
import logInLanguage from './logIn.language'
import homeLanguage from './home.language'
import menuLanguage from './menu.language'
import passwordLanguage from './password.language'
import headerLanguage from './header.language'
import rightSideLanguage from './rightSide.language'
import titleLanguage from './title.language'

export const getLanguage = (value = 'fr') => ({
  form:           formLanguage[value],
  login:          logInLanguage[value],
  home:           homeLanguage[value],
  menu:           menuLanguage[value],
  password:       passwordLanguage[value],
  header:         headerLanguage[value],
  rightSide:      rightSideLanguage[value],
  title:          titleLanguage[value],
})

export default {
  fr: getLanguage(),
  us: getLanguage('us'),
}
