import React, {
  useState, useEffect, useContext,
} from 'react'
import {
  Form, Input, Label, Icon, Header,
} from 'semantic-ui-react'
import InfoInput from './InfoInput'
import { Store } from '../store/store'

const progressBarPassword = ['password-progress-bar-25', 'password-progress-bar-50', 'password-progress-bar-75', 'password-progress-bar-100']

const InputPassword = ({
  onKeyPress = () => { },
  name,
  onChange,
  value,
  initialValue,
  error,
  labelObject,
  styles = { container: '' },
  placeholder,
  required,
  silentRequired = false,
  toCheck = false,
  disabled,
  options = [],
  maxLength = null,
  fluid = false,
  legend = null,
  inline = true,
  responsive = true,
  style = {},
  signIn,
  labelStyle = {},
  centered = false,
  info = null,
  label,
  handleEffect,
  requiredLang,
  fileLang,
  fileErrorLang,
  forwardRef = null,
  children,
  onFocus = () => { },
}) => {
  const {
    state :
    {
      language : {
        password : {
          conditionPassword: conditionPasswordLang,
          strengthWord: strengthWordLang,
        },
      },
    },
  } = useContext(Store)

  const [visible, setVisible] = useState(false)
  const [displayPasswordHeader, setDisplayPasswordHeader] = useState(false)
  const [valuePassword, setValuePassword] = useState('')
  const [strengthPassword, setStrengthPassword] = useState(null)
  const onInputFocus = one => {
    setDisplayPasswordHeader(true)
    onFocus(one)
  }
  const show = () => setVisible(!visible)

  useEffect(() => {
    const regexPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
    const regexUpper = new RegExp('[A-Z]')
    const regexLower = new RegExp('[a-z]')
    const regexNumber = new RegExp('[0-9]')

    if (signIn) {
      if (regexPass.test(valuePassword)) {
        return setStrengthPassword(3)
      } else {
        return setStrengthPassword(0)
      }
      // revoir ce système plus tard avec les √ qui se colorient et passent en opacité 0
      if (valuePassword === '') return setStrengthPassword(null)
      if (valuePassword.length < 8) return setStrengthPassword(0)
      else if (regexPass.test(valuePassword)) { return setStrengthPassword(3) }

      let count = 0
      if (regexNumber.test(valuePassword)) count++
      if (regexLower.test(valuePassword)) count++
      if (regexUpper.test(valuePassword)) count++

      setStrengthPassword(count)
    }
  }, [valuePassword])

  const checkValidityPassword = event => {
    setValuePassword(event.target.value)
    const regexPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
    if (signIn) {
      if (!regexPass.test(event.target.value)) return handleEffect({ target: { value: '' } }, { value: '' })
    }
    handleEffect(event, { value: event.target.value })
  }
  const onBlur = event => {
    const regexPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
    if (signIn) {
      if (!regexPass.test(valuePassword)) return handleEffect({ target: { value: '' } }, { value: '' })
    }
    handleEffect(event, { value: valuePassword })
  }
  return (
    <Form.Field
      required={required}
      className={styles.container}
      style={responsive ? centered ? { display: 'flex', justifyContent: 'center' } : { display: 'flex' } : {}}
      inline={inline} >
      {label &&
        <label style={labelStyle} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label>
      }
      <Input
        {...(forwardRef ? { ref: forwardRef } : {})}
        onKeyPress={onKeyPress}
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        type={visible ? 'text' : 'password'}
        value={valuePassword || ''}
        placeholder={placeholder}
        onChange={checkValidityPassword}
        onBlur={onBlur}
        fluid={fluid}
        style={style}
        onFocus={onInputFocus}
        {...(maxLength ? { maxLength } : {})}
        icon={<Icon name={visible === false ? 'eye slash' : 'eye'} link onClick={show} />}
      />
      {signIn ?      <React.Fragment>
        {displayPasswordHeader && <div className="mv-5 ta-right">
          {/* <div className="password-meter">
            <div className={progressBarPassword[strengthPassword]}></div>
          </div> */}
          <Header as={'h5'} className={`
          value-password-metter ${strengthPassword > 0 ? 'color-green' : 'color-red'}
            `}>
            {strengthWordLang[strengthPassword]}
          </Header>
        </div>}
        {legend && conditionPasswordLang.map(values => (
          <div key={values}>
            <p className="legend-input">-{values}</p>
          </div>
        ))}
      </React.Fragment>          :
      <React.Fragment>
        {legend && <div><p className="legend-input">{legend}</p></div>}</React.Fragment>
      }

      {info && <InfoInput info={info} />}
      {children}
      {required && error && !silentRequired &&
        <Label style={{
          display:      'inline-table',
          marginTop:    'auto',
          marginBottom: 'auto',
        }} pointing={'left'} color={'red'} basic >
          {requiredLang}
        </Label>
      }
    </Form.Field>
  )
}

export default InputPassword
