import { fetchApi } from "../../helpers";

export const getCompanies = () => fetchApi('/companies')

export const emailOwner = (ownerEmail, code) => fetchApi('/admin/sendEmailOwner/' + ownerEmail, {
    body: {
        code,
    }
})

export const createCompany = ({email, name, siret}) => fetchApi('/company', {
    method: 'POST',
    body: {
      email,
      name,
      siret
    }
  })
  
  export const deleteCompanyAPI = (id) => fetchApi('/company/' + id, {
    method: 'DELETE',
  })

export default {
  getCompanies,
  createCompany,
  deleteCompanyAPI,
}
