import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { getUsersFromCompany } from './helpers';
import { useParams, useHistory } from 'react-router-dom';
import { userRoleReadable } from '../../helpers';
import CompanyStats from './CompanyStats';

const Page = () => {
	const headers = ['Id', 'Prénom', 'Nom', 'Date de création', 'Taille', 'Rôle']
	const params = useParams()
	const history = useHistory()
	const companyId = params.companyId ? Number(params.companyId) : null;
	const [users, setUsers] = useState([])
	useEffect(() => {
		if (!companyId) {
			return history.push()
		}
		getUsersFromCompany(companyId)
			.then(setUsers)
	}, [])
	return (
		<div className='width-90 flex column'>
			{!!(users && users.length > 0) && <CompanyStats users={users} companyId={companyId} />}
			<div className='height-400 mt-30'>
				<Table celled>
					<Table.Header>
						<Table.Row>
							{headers.map((e, index) => <Table.HeaderCell key={e} colSpan={index === headers.length - 1 ? '2' : '1'}>{e}</Table.HeaderCell>)}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{users.map((user, key) => <Table.Row key={key}>
							<Table.Cell>
								{user.id}
							</Table.Cell>
							<Table.Cell>
								{user.firstname}
							</Table.Cell>
							<Table.Cell>
								{user.lastname}
							</Table.Cell>
							<Table.Cell>
								{new Date(user.created_at).toLocaleDateString()}
							</Table.Cell>
							<Table.Cell>
								{user.size} Bytes
							</Table.Cell>
							<Table.Cell>
								{userRoleReadable(user.role)}
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>
			</div>

		</div>
	);
};

export default Page;