import React, { useContext } from 'react'
import {
  Grid, GridRow, Checkbox,
} from 'semantic-ui-react'
import { Store } from './../store/store'
import Menu from '../containers/menu'

const RightSide = ({
  children,
}) => (
  <Grid style={{ height: '100%' }} className="margin-0">
    {children}
  </Grid>
)

const LogGrid = ({
  history,
  children,
}) => {
  // si pas de header au final justea enlever les grid row
  const { state:{ menu }, dispatch } = useContext(Store)
  const menuClassName = menu ? '' : 'hide-menu'
  const handleMenuState = () => dispatch({ type: `${menu ? 'HIDE' : 'SHOW'}_MENU` })
  return (
    <Grid className={`full-height text-color log-grid-container ${menuClassName}`} >
      <div className="menu-hide-icon-container">
        <Checkbox onClick={handleMenuState} toggle checked={menu} />
        {/* <Icon color="yellow" name={'hand pointer'} size="big" /> */}
      </div>
      {/* <GridRow className="padding-0" style={{ height: '7vh' }}>
      </GridRow> */}
      <GridRow className="padding-0" style={{ height: '100vh' }}>
        <Grid.Column className="bg-violet padding-0 log-grid-menu-container">
          <Menu history={history} />
        </Grid.Column>
        <Grid.Column className="padding-0 log-grid-right-side-container">
          <RightSide >
            {children}
          </RightSide>
        </Grid.Column>
      </GridRow>
    </Grid>
  )
}

export default LogGrid
