import React from 'react'
import { Label } from 'semantic-ui-react'

export default ({
  display, pointing, color = 'black', style = {},
}) => (
  <Label
    style={style}
    basic
    color={color}
    pointing={pointing}>
    {display}
  </Label>
)
