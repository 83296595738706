import React, { useContext } from 'react'
import {
  Flag, Dropdown, Menu,
} from 'semantic-ui-react'
import { Store } from '../store/store'

const LanguageSelection = ({ changeLanguage = () => {} }) => {
  const options = [
    {
      key:     1,
      text:    'English',
      value:   'us',
      content: (
        <Flag name='us' />
      ),
    }, {
      key:     2,
      text:    'Français',
      value:   'fr',
      content: (
        <Flag name='france' />
      ),
    },
  ]

  const { dispatch, state:{ languageValue } } = useContext(Store)
  const handleChange = async (e, { value }) => {
    changeLanguage(value)
    dispatch({ type: 'CHANGE_LANGUAGE', payload: value })
  }

  return (<Menu compact>
    <Dropdown trigger={<Flag name={languageValue} />}
      options={options}
      onChange={handleChange}
      item
      closeOnChange
    />
  </Menu>
  )
}

export default LanguageSelection
