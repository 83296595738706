import React, { useContext } from 'react'
import {
  Grid, Image, ImageGroup, GridColumn, Responsive, Divider,
} from 'semantic-ui-react'
import logo from '../images/logoSlogan.svg'
import FormComponent from './Form'
import IconWithText from './IconWithText'
import OwnIcons from './OwnIcons'
import { Store } from '../store/store'
import LottieControl from './LottieControl'
import * as animationJson from './unLog.json'
import Fade from 'react-reveal/Fade'
import GoogleButton from './GoogleButton'

const UnLogGrid = ({
  page = 'login',
  message = '',
  inputs,
  sendForm,
  messageLinkeable,
  navigateHandle,
  submitMessage,
  loader = false,
  noConfirmationMailLang = {},
}) => {
  const {
    state: {
      language:{
        login:{
          leftSide: {
            logIn: logInLang,
            signIn: signInLang,
            discuss: discussLang,
            share: shareLang,
            teletravail: teletravailLang,
            optimiseInteraction: optimiseInteractionLang,
          },
      },
    },
    },
  } = useContext(Store)

  const leftSide = () => (
    <Grid className="full-height padding-0 bg3 text-color" centered>
      <Grid.Column className="left-side-container bg4" width={12}>
        <Grid.Row className="bg1">
          <ImageGroup as="a" href="/" align="left" size="medium">
            <Image src={logo} />
          </ImageGroup>
        </Grid.Row>
        <Grid.Row className="page-name-container bg2">
          <GridColumn style={{ width: '100%' }}>
            <Grid.Row style={{ marginBottom: '1.5em' }}>
              <GridColumn width={16}>
                { page === 'login' || page === 'signup' ? <>
                <span className="selected-text text-active">{page === 'login' ? logInLang : signInLang}</span>
                <span className="pointer" onClick={navigateHandle}>{page === 'login' ? signInLang : logInLang}</span> </> :
                <span className="title-form">{message}</span>
                }
              </GridColumn>
            </Grid.Row>
            <Grid.Row style={{ width: '100%' }}>
              <GridColumn style={{ width: '100%' }}>
                <FormComponent
                  responsive={false}
                  segment={false}
                  inputs={inputs}
                  sendForm={sendForm}
                  messageLinkeable={messageLinkeable}
                  messages={{ submit: submitMessage }}
                  loader={loader}
                  noConfirmationMailLang={noConfirmationMailLang}
                />
              </GridColumn>
            </Grid.Row>
            <Grid.Row></Grid.Row>
          </GridColumn>

        </Grid.Row>
        <Grid.Row className="bg5 mt-10">
          <Fade duration={1800} delay={500}>
            <IconWithText classContainer="margin-bottom-login-icon" name="review" text={discussLang} />
          </Fade>
          <Fade duration={1800} delay={2000}>
            <IconWithText classContainer="margin-bottom-login-icon" name="chart" text={shareLang} />
          </Fade>
          <Fade duration={1800} delay={3000}>
            <IconWithText classContainer="margin-bottom-login-icon" name="light" text={teletravailLang} />
          </Fade>
          <Fade duration={1800} delay={4000}>
            <IconWithText classContainer="margin-bottom-login-icon" name="decision" text={optimiseInteractionLang} />
          </Fade>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )

  const rightSide = () => (
    <div>
      <LottieControl
        width={600}
        height={300}
        animationData={animationJson} />
    </div>
  )

  const standartRender = () => (
    <Grid className="full-height text-color" >
      <Grid.Column className="full-height padding-0" width={6}>
        {leftSide()}
      </Grid.Column>
      <Grid.Column className="bg-violet full-height padding-0 centered-container" width={10}>
        {rightSide()}
      </Grid.Column>
    </Grid>
  )

  return (<>
    <Responsive minWidth={780}>{standartRender()}</Responsive>
    <Responsive maxWidth={779}>{leftSide()}</Responsive>
  </>
  )
}

export default UnLogGrid
