import React, { useContext, useEffect } from 'react';
import { Store } from '../../../store/store';
import RightSideLoading from '../../../components/RightSideLoading';
import Page from './Page';

export default () => {
	const {dispatch} = useContext(Store)
	useEffect(() => {
		dispatch({ type: 'LOADING_OFF' })
	  }, [])
	
	  return (
		<RightSideLoading>
		  <Page />
		</RightSideLoading>)
};
