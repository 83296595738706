export const FR = {
  campaign:  'Démarrer une nouvelle étude',
  dashboard: 'Tableaux de bord',
  brands:    'Marques',
  items:     'Études',
  private:   'Espace personnel',
  guides:    'Guides',
  profil:    'Profil',
  payment:   'Paiement',
  billing:   'Facturation',
  plan:      'Forfait',
  support:   'Support',
}

export const EN = {
  campaign:  'Start new survey',
  dashboard: 'Dashboard',
  brands:    'Brands',
  items:     'Surveys',
  private:   'Private space',
  guides:    'Guides',
  profil:    'Profil',
  payment:   'Payment method',
  billing:   'Billing & Invoices',
  plan:      'Plan',
  support:   'Support',
}

export default {
  fr: FR,
  us: EN,
}
