import React, { useEffect, useState } from 'react';
import { Table, Header } from 'semantic-ui-react';
import { userRoles, capitalizeFirstLetter } from '../../helpers';
import { getCompany } from './helpers';

const CompanyStats = ({
	users = [],
	companyId
}) => {
	const headers = [
		'Utilisateurs',
		'Invités',
		'Standart',
		'Admin',
		"Taille",
		"Responsable"
	]
	const [stats, setStats] = useState()

	const [company, setCompany] = useState('')

	useEffect(() => {
		if (companyId && companyId > 0) {
			getCompany(companyId)
				.then(setCompany)
		}
	}, [companyId])
	const[size, setSize] =useState()

	useEffect(() => {
		if (users && users.length > 0) {
			setStats({
				users: {
					all: users.length,
					guests: users.filter(({ role }) => role === userRoles.GUEST).length,
					standarts: users.filter(({ role }) => role === userRoles.USER).length,
					admins: users.filter(({ role }) => role === userRoles.ADMIN).length,
				},
			})
			setSize(users.map(u => u.size).reduce((u1, u2) => u1 + u2), 0)
		}
	}, [users])
	return (
		<div className='mb-10'>
				<Header as="h1">{capitalizeFirstLetter((company||{}).name ||'')}</Header>
		<Table celled>
			<Table.Header>
				<Table.Row>
					{headers.map((e, index) => <Table.HeaderCell key={e} colSpan={index === headers.length - 1 ? '2' : '1'}>{e}</Table.HeaderCell>)}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						{stats && stats.users ? stats.users.all : "En cours de chargement"}
					</Table.Cell>
					<Table.Cell>
						{stats && stats.users ? stats.users.guests : "En cours de chargement"}
					</Table.Cell>
					<Table.Cell>
						{stats && stats.users ? stats.users.standarts : "En cours de chargement"}
					</Table.Cell>
					<Table.Cell>
						{stats && stats.users ? stats.users.admins : "En cours de chargement"}
					</Table.Cell>
					<Table.Cell>
					{size ? `${size} Bytes` : "En cours de chargement" }
					</Table.Cell>
					<Table.Cell>
						{(company || {}).owner_email || "En cours de chargement"}
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
		</div>
	);
};

export default CompanyStats;