import React, { Component } from 'react'
import {
  Button,
} from 'semantic-ui-react'

const acceptedTypes = [
  'image/png',
  'image/x-png',
  'image/jpeg',
  'image/jpg',
  'image/JPG',
  'image/gif',
].join(',')

class ImageInput extends Component {
  constructor (props) {
    super(props)
    this.fileInputRef = React.createRef()
    this.state = {
      fileName:     null,
      sizeTooLarge: false,
    }
  }

	getBase64 = (file, cb) => {
	  const reader = new FileReader()
	  reader.readAsDataURL(file)
	  reader.onload = () => {
	    cb(reader.result)
	    this.setState({ fileName: file.name })
	    this.state.sizeTooLarge && this.setState({ sizeTooLarge: false })
	  }
	  reader.onerror = function (error) {
	    process.env.NODE_ENV !== 'production' && console.log('Error: ', error)
	  }
	}

	uploadImage = evt => {
	  const file = evt.target.files[0]
	  if (!file) { return }
	  if (file.size > 1000000) { // >1MO
	    return this.setState({ sizeTooLarge: true })
	  }
	  this.setState({ fileName: file.name })
	  this.setState({ sizeTooLarge: false })
	  // const base64Image = image => this.props.onChange({ target: { value: image } })
	  this.props.onChange({ target: { value: file } })
	  return URL.createObjectURL(file)
	  // return this.getBase64(file, base64Image)
	}

	render () {
	  return (
			<>
				<Button
				  type="button"
				  content={
				    this.state.sizeTooLarge ? this.props.fileErrorLang :
				    this.state.fileName || this.props.fileLang + ' (< 1MB)'}
				  color={this.state.sizeTooLarge ? 'red' : 'purple'}
				  labelPosition="left"
				  icon="file"
				  onClick={() => this.fileInputRef.current.click()}
				/>
				<input
				  id={this.props.name + (this.props.initialValue ? '-' + this.props.initialValue : '')}
				  name={this.props.name}
				  ref={this.fileInputRef}
				  type="file"
				  accept={acceptedTypes}
				  hidden
				  onChange={this.uploadImage}
				  onBlur={this.uploadImage}
				/>
			</>
	  )
	}
}

export default ImageInput
