import React from 'react'
import {
  Form, Select,
} from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'

const InlineSelect = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  maxLength = null,
  label,
  legend = null,
  required,
  error,
  silentRequired = false,
  initialValue,
  handleEffect,
  options,
  requiredLang,
  info,
}) => {
  return (
    <Form.Field
      required={required}
      className="form-container"
    >
      {label &&
				<label className="conform-label" htmlFor={name + (initialValue ? '-' + initialValue : '')}>
				  {label}
				</label>
      }
      <Select
        style={{
          width:       'auto',
          marginLeft:  label ? '10px' : '0',
          marginRight: required && error && !silentRequired ? '10px' : '0',
        }}
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleEffect}
        onBlur={handleEffect}
        options={options} />
      {legend && <p className="legend-input">{legend}</p>}
      {info && <InfoInput info={info} />}
      {required && error && !silentRequired &&
				<Label
				  style={{ display: 'inline-table' }}
				  display={requiredLang}
				  pointing={'left'}
				  color={'red'} />
      }
    </Form.Field>
  )
}

export default InlineSelect
