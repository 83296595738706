import { fetchApi, saveTokens } from '../../helpers'


export const login = ({ email, password }, dispatch) =>
  fetchApi(`/admin/login/${email}`,
    {
      method: 'POST',
      body: { password },
    })
    .then(tokens => saveTokens(tokens, dispatch))

export const parseEncodeObject = encoded =>
  JSON
    .parse(Buffer.from(decodeURIComponent(encoded), 'base64').toString('utf-8'))

export default {
  login,
  parseEncodeObject,
}
