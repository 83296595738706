import React, {
  useEffect, useContext, useState,
} from 'react'
import { Store } from './store/store'
import { UnAuthRoute, AuthRoute } from './components/GuardRoutes'
import Home from './containers/home/Home'
import Page from './containers/company/Page'
import Login from './containers/account/Login'
import SignIn from './containers/account/SignIn'
import { getloginPaths } from './containers/helpers'
import Loader from './components/Loader'
import LogGrid from './components/LogGrid'
import Header from './containers/header/Header'
import { Route, Switch } from 'react-router-dom'
import GoogleConnect from './containers/account/Login/GoogleConnect'

const AuthRoutes = ({
  history,
}) => {
  const loginPaths = getloginPaths()
  return (<>
    <LogGrid history={history}>
      <Switch>
        <AuthRoute exact path={loginPaths.home} component={Home} />
        <AuthRoute exact path={loginPaths.page} component={Page} />
      </Switch>
    </LogGrid>
  </>)
}

function App () {
  const { state, dispatch } = useContext(Store)
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug:      false, // enable logs
      }
    }
  }, [])

  return (
    <div className='App'>
      {!state.languageHidden && <Header />}
      {loader ? <Loader /> :

      <Switch>
        <Route exact path='/googleConnect/:token' component={GoogleConnect} />
        <UnAuthRoute exact path='/signUp' component={SignIn} />
        <UnAuthRoute exact path='/login' component={Login} />
        <AuthRoute path='/' component={AuthRoutes} />
      </Switch>
      }
    </div>
  )
}

export default App
