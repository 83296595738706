import React, { useState } from 'react'
import { useEffect } from 'react'
import {
  Button, Table, Input, Header,
} from 'semantic-ui-react'
import FormComponent from '../../../components/Form'
import { emailOwner } from './helpers'
import { useHistory } from 'react-router-dom'

const keys = ['id', 'siret','name', 'owner_email']

const HomeComponent = ({
  companies,
  inputs = [],
  sendForm = () => { },
  deleteCompany = () => { },
}) => {

  const headers = ['Id', 'Company','Siret', 'Owner', 'Action']
  const [filter, setFilter] = useState('')
  const [activateFilter, setActivateFilter] = useState(false)
  const [filteredCompanies, setFilteredCompanies] = useState([])

  useEffect(() => {
    setFilteredCompanies(companies)
  }, [companies])

  const handleFilterChange = event => {
    setFilter(event.target.value)
    if (event.target.value === '') {
      setActivateFilter(true)
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setActivateFilter(true)
    }
  }

  useEffect(() => {
    if (activateFilter === true) {
      const actualFilters = {}
      const filters = filter.split(' ')

      filters.forEach((string) => {
        const arr = string.split(':')
        if (arr.length === 2 && keys.includes(arr[0])) {
          actualFilters[arr[0]] = arr[1]
        } else if (arr.length === 1) {
          actualFilters['company'] = arr[0]
        }
      })

      setFilteredCompanies(companies.map(company => {
        for (const [key, value] of Object.entries(actualFilters)) {
          if (!company[key].toString().includes(actualFilters[key].toString())) {
            return null
          }
        }
        return company
      }).filter(e => !!e))
      setActivateFilter(false)
    }
  }, [companies, filter, activateFilter])
const history = useHistory()
  const navigateToCompanyPage = (id) => history.push('/company/'+id)

  return (
    <div className='width-90 flex column'>
      <div>
        <Input value={filter} onChange={handleFilterChange} onKeyPress={handleKeyPress}>
          <input />
          <Button onClick={() => setActivateFilter(true)}>Filter</Button>
        </Input>
      </div>
      <div className='height-400 mt-30'>
        <Table celled>
          <Table.Header>
            <Table.Row>
              {headers.map((e, index) => <Table.HeaderCell key={e} colSpan={index === headers.length - 1 ? '2' : '1'}>{e}</Table.HeaderCell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredCompanies.map(company => <Table.Row key={company.id}>
              <Table.Cell>
                {company.id}
              </Table.Cell>
              <Table.Cell>
                {company.name}
              </Table.Cell>
              <Table.Cell>
                {company.siret}
              </Table.Cell>
              <Table.Cell>
                {company.owner_email}
              </Table.Cell>
              <Table.Cell>
                <Button onClick={() => navigateToCompanyPage(company.id)} color='teal'>Infos</Button>
              </Table.Cell>
              <Table.Cell>
                <Button onClick={() => emailOwner(company.owner_email, company.id)} color='teal'>Mail</Button>
              </Table.Cell>
              <Table.Cell>
                <Button onClick={() => {
                  deleteCompany(company.id)
                }
                } color='red'> DELETE</Button>
              </Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
      </div>
      <div className='mt-30'>
        <Header as="h2">Créer un nouvel espace</Header>
        <FormComponent
          sendForm={sendForm}
          inputs={inputs}
          message={{ submit: 'SUBMIT' }}
        />
      </div>
    </div>
  )
}

export default HomeComponent
