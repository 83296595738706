import { fetchApi } from '../../helpers'

export const createAdmin = ({
   password, email,
}) => fetchApi('/admin', {
    body: {
      password,
      email
    },
  })


export default {
  createAdmin,
}
