import React from 'react'
import OwnIcons from './OwnIcons'

const IconWithText = ({
  name,
  classContainer = '',
  text = '',
}) => {
  return (
    <div className={`${classContainer} flex`}>
      <span><OwnIcons className="fill-color" size="30px" name={name} /></span>
      <div className="text-icon-login">{text}</div>
    </div>
  )
}

export default IconWithText
