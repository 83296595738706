export const FR = {
  conditionPassword: ['8 caractères minimum', '1 chiffre', '1 lettre majuscule', '1 lettre minuscule'],
  strengthWord:      ['INCOMPLET', 'INCOMPLET', 'INCOMPLET', 'BON'],
}

export const EN = {
  conditionPassword: ['8 characters minimum', '1 number', '1 uppercase letter', '1 lowercase letter'],
  strengthWord:      ['INCOMPLETE', 'INCOMPLETE', 'INCOMPLETE', 'GOOD'],
}

export default {
  fr: FR,
  us: EN,
}
