import React, { useContext } from 'react'
import { Store } from '../../store/store'
import { readNotification } from '../header/Header/helpers'
import Notification from '../../components/Notification'

const NotificationContainer = () => {
  const { state: { notifications = [] }, dispatch } = useContext(Store)

  const remove = id => readNotification(id, dispatch)
  return (
    <div className="notifications-container menu-items">
      {notifications.map(
        (notification, key) =>
          <Notification
            key={key}
            {...notification}
            remove={remove}
          />)}
    </div>

  )
}

export default NotificationContainer
