import React from 'react'
import Lottie from 'react-lottie'

const LottieControl = ({
  animationData,
  loop = true,
  autoplay = true,
  height = 400,
  width = 400,
  isComplete = () => {},
}) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData:    animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <div>
    <Lottie options={defaultOptions}
      height={height}
      width={width}
      eventListeners={[
        {
          eventName: 'complete',
          callback:  isComplete,
        },
      ]}
      isClickToPauseDisabled
    />
  </div>
}

export default LottieControl
