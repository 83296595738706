import React, {
  useEffect, useContext,
} from 'react'
import Loader from './Loader'
import { Store } from '../store/store'

const RightSideLoading = ({
  history, children,
}) => {
  const { state: { rightSideLoading }, dispatch } = useContext(Store)

  useEffect(() => {
    return () => dispatch({ type: 'LOADING_ON' })
  }, [])

  return (
    <React.Fragment>
      {rightSideLoading ? <Loader /> : children}
    </React.Fragment>
  )
}

export default RightSideLoading
