import React from 'react'
import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import 'react-rater/lib/react-rater.css'
import 'antd/dist/antd.css'
import './style.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from './store/store'
import { Router } from 'react-router-dom'
import { history } from './containers/helpers'

ReactDOM.render(<Router history={history}>
  <Provider>
    <App />
  </Provider>
</Router>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
