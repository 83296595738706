export const EN = {
  logOut:  'Log out',
  profile: 'My profile',
}

export const FR = {
  logOut:  'Se déconnecter',
  profile: 'Mon profil',
}

export default {
  fr: FR,
  us: EN,
}
