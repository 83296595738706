import React from 'react'
import {
  Button,
} from 'semantic-ui-react'

const ButtonSendForm = ({
  button,
  onSubmit,
  messages,
  error,
  responsive,
  emptyFields = [],
  submitLang,
  loader = false,
}) => {
  return (
    <div>
      {/* {error && responsive && <MyMessage
        header='Champs manquants'
        content={() => <div className="empty-fields-responsive">{emptyFields.map(
          (field, index) => <span key={index}>{field}</span>
		  )}</div>}
      /> } */}
      <Button loading={loader} color={button.color} as='a' onClick={onSubmit}>
        <Button.Content visible>{messages.submit || submitLang}</Button.Content>
        {/* <Button.Content hidden>
          <Icon name='arrow right' />
        </Button.Content> */}
      </Button>
    </div>
  )
}

export default ButtonSendForm
