/* eslint-disable import/no-anonymous-default-export */
export const FR = {
  leftSide: {
    logIn: 'Se connecter',
    signIn: "S'inscrire",
    firstname: 'Prénom',
    lastname: 'Nom',
    mail: 'Adresse mail',
    password: 'Mot De Passe',
    gender: 'Sexe',
    male: 'Homme',
    female: 'Femme',
    forgotPassword: 'Mot de passe oublié ?',
    noConfirmationMail: 'Je n\'ai pas reçu de mail de confirmation !',
    valid: 'Se connecter',
    validUp: 'S\'inscrire',
    addInfo: 'Ajouter informations',
    addInfoTitle: 'Nous avons besoin de quelques informations en plus avant que vous ne puissiez accéder votre page d\'acceuil.',
    successMessage: 'Votre compte a été créé avec succès, veuillez valider votre adresse email 🤗.',
    discuss: 'Discutez avec vos collaborateurs',
    share: 'Partagez vos documents',
    teletravail: 'Travaillez à distance sans contraintes',
    optimiseInteraction: 'Optimisez vos process',
    errorTitle: 'Erreur de connexion',
    unlock: 'Débloquer votre compte',
    typeCode: 'Tappez le code reçu par mail',
    forgotPasswordTitle: 'Renseignez votre email afin de recevoir votre nouveau mot de passe',
    validForgot: 'Envoyer',
    newPasswordTitle: 'Pour activer votre compte, renseignez votre email et votre nouveau mot de passe',
    newPassword: 'Nouveau mot de passe',
    accountNotValidated: 'Veuillez confirmer votre compte grâce au mail ou renvoyer un lien dans la page suivante',
    redirectConfirm: 'Allez sur la page de renvoi de mail',
    signupModalTitle: 'Inscription',
    signupModalTextSuccess: 'Votre compte a été créé avec succès, veuillez valider votre adresse email 🤗.',
    googleAccountText: 'Ce compte a été associé a votre compte google. Vous pourrez vous connecter avec l\'email et le mot de passe enregistré une fois l\'email validé.',
    signupModalButtonSuccess: 'Aller vers la page de connexion',
    signupModalButtonError: 'Fermer',
    confirmErrorTitle: 'Erreur de confirmation de compte',
    confirmErrorButton: 'Aller vers la page de connexion',
    loginGoogle: 'Continuer avec Google',
    signUpGoogle: 'Continuer avec Google',
    or: 'OU',
    termsAndConditionsAgreement: 'J\'ai lu et accepte ',
    termsAndConditions: 'les conditions d\'utilisations Leenkeet',
    userAgreementPlaceholder: 'Conditions d\'utilisation',
  },
  rightSide: {
    title: 'Récoltez, décidez ',
    subtitle: 'Klack vous permet d\'interagir directement avec vos futurs et actuels consommateurs afin d\'obtenir le maximum d’informations qualifiées pour optimiser vos ventes.',
    link: 'En découvrir plus sur',
  },
}
export const EN = {
  leftSide: {
    logIn: 'Log In',
    signIn: 'Sign Up',
    firstname: 'First name',
    lastname: 'Last name',
    mail: 'Email address',
    password: 'Password',
    gender: 'Gender',
    male: 'Man',
    female: 'Woman',
    forgotPassword: 'Forgot password ?',
    noConfirmationMail: 'I did not receive a confirmation email !',
    valid: 'Sign In',
    validUp: 'Sign up',
    addInfoTitle: 'We just need a few more informations before you can access your home page.',
    addInfo: 'Add informations',
    successMessage: 'Your account has been created with success. Please check your email for next instructions 🤗.',
    discuss: 'Discuss with your collaborators',
    share: 'Share your files',
    teletravail: 'Distancial work without constraints',
    optimiseInteraction: 'Optimise your process',
    errorTitle: 'Login error',

    unlock: 'Unlock your account',
    typeCode: 'Type here the code you received by email',
    forgotPasswordTitle: 'Please provide your email to receive your new password code',
    validForgot: 'Send',
    newPasswordTitle: 'To reactivate your account, please enter your email and your new password',
    newPassword: 'New password',
    accountNotValidated: 'Please confirm your account via email or return a link to the following page',
    redirectConfirm: 'Go to the email landing page',
    signupModalTitle: 'Signup',
    signupModalTextSuccess: 'Your account has been created with success. Please check your email for next instructions 🤗.',
    googleAccountText: 'This account has been associated with your google account. You will be able to login with this email and password once the email has been verified.',
    signupModalButtonSuccess: 'Go to the login page',
    signupModalButtonError: 'Close',
    confirmErrorTitle: 'Account confirmation error',
    confirmErrorButton: 'Go to the login page',
    loginGoogle: 'Continue with Google',
    signUpGoogle: 'Continue with Google',
    or: 'OR',
    termsAndConditionsAgreement: 'I have read and approve ',
    termsAndConditions: 'Leenkeet\'s terms and conditions of use',
    userAgreementPlaceholder: 'Terms and conditions',
  },
  rightSide: {
    title: 'Collect, decide',
    subtitle: 'Klack allows you to interact directly with your present and future consumers in order to obtain the maximum amount of qualified informations to optimize your sales',
    link: 'Discover more on',
  },
}

export default {
  fr: FR,
  us: EN,
}
