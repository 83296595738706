import React, {
  useState, useContext,
} from 'react'
import {
  Form, Message, Segment, Button, Container, Header, List,
} from 'semantic-ui-react'
import Input from './Input'
import Radio from './RadioGroup'
import MessageLinkeable from './MessageLinkeable'
import HorizontalCenteredGrid from './HorizontalCenteredGrid'
import ButtonSendForm from './ButtonSendForm'
import { Store } from '../store/store'
import OwnIcons from './OwnIcons'

const FormComponent = ({
  inputs = [],
  sendForm = () => { },
  onUpdate = () => { },
  messages = {},
  segment = false,
  button = {
    align: 'left',
    color: 'violet',
  },
  messageLinkeable = {},
  centered = false,
  responsive = true,
  segmentStyle = {},
  loader = false,
  noConfirmationMailLang = {},
  unavailable = null,
}) => {
  const {
    state: {
      languageValue,
      language: {
        form: {
          emptyFields: emptyFieldsLang,
          submit: submitLang,
        },
      },
    },
  } = useContext(Store)
  const initValues = () => {
    const init = {}
    inputs.map(input => {
      let initValue = input.value || null
      if (!initValue && input.required) {
        const { radios = [{}] } = input
        initValue = radios[0].value
      }
      return (init[input.name] = initValue)
    })
    return init
  }
  const [values, setValues] = useState(initValues())

  const getRadioValue = label => inputs
    .find(({ radios = [] }) =>
      radios.find(radio => radio.label === label))
    .radios
    .find(radio => radio.label === label).value

  const updateValues = ({
    name, value, label, type,
  }) => {
    error && checkRequired() && setError(false)
    setValues({
      ...values,
      [name]: type === 'radio' ? getRadioValue(label) : value,
    })
    onUpdate({
      ...values,
      [name]: type === 'radio' ? getRadioValue(label) : value,
    })
  }

  const [emptyFields, setEmptyFields] = useState([])
  const labelExtract = label => typeof label === 'object' ? label[languageValue] : label
  const checkRequired = () => {
    const required = inputs.filter(({ name, required }) => required && !values[name])
    if (required.length > 0) {
      setEmptyFields(required.length === 1 ? [labelExtract(required[0].label) || required[0].placeholder] :
        required.map(({ label, placeholder }) => labelExtract(label) || placeholder))
      return false
    } else {
      return true
    }
  }

  const submitForm = () => checkRequired() ? sendForm(values) : setError(true)

  const [error, setError] = useState(false)
  const onSubmit = evt => {
    evt.preventDefault()
    submitForm()
  }
  const onEnter = e => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }

  const getUnavailable = () => {
    if (!unavailable) return
    switch (typeof unavailable) {
    case 'string':
      return <div className="centered-container limit-container">
        <Header
          as="h4"
          className="limit ta-center">
          {unavailable}
        </Header>
      </div>
    case 'object':
      return <div className="centered-container limit-container ">
        <div className="upgrade-button-container">
          <Button
            className="color-white purple-background-button"
            onClick={unavailable.onClick}>
            {unavailable.message}
          </Button>
          {unavailable.icon &&
            <OwnIcons name={unavailable.icon} size={unavailable.iconSize || 75}/>
          }
        </div>
      </div>
    default:
      return null
    }
  }

  const renderForm = () => (
    <React.Fragment>
      <Form error={error} className={unavailable ? 'unavailable' : ''}>
        <div className={responsive && error ? 'flex' : ''}>
          <div className={`field-set ${/* responsive ? 'max-height-form-fields' : */''}`}>
            {inputs.map((props, id) =>
              props.type === 'radio' ? <Radio
                key={id}
                onChange={updateValues}
                {...props}
              /> :
              <Input
                onKeyPress={onEnter}
                key={id}
                onChange={updateValues}
                responsive={responsive}
                {...props}
                //  toCheck={}
              />
            )}
          </div>
          {error && <Message
            error={true}
            header={emptyFieldsLang}
            content={<List as="ul" className="form-error-ul" bulleted>{emptyFields.map((a, index) => <List.Item as='li' key={index}>{a}</List.Item>)}</List>}
          />}
        </div>

        {messageLinkeable && <MessageLinkeable style={{ marginTop: '1.2em', marginBottom: '1.2em' }} {...messageLinkeable} />}
        {messageLinkeable.message && <MessageLinkeable style={{ marginTop: '-0.7em', marginBottom: '1.2em' }} {...noConfirmationMailLang} />}
        {!button ? null : button.align === 'left' ? <ButtonSendForm
          button={button}
          onSubmit={onSubmit}
          messages={messages}
          error={error}
          responsive={responsive}
          emptyFields={emptyFields}
          submitLang={messages.submit || submitLang}
        /> :
        <Button style={{ [button.align]: 0 }} color={button.color} as='a' onClick={onSubmit} loading={loader} className="absolute-important z-index-third purple-background-button">{messages.submit || submitLang}</Button>
        }

      </Form>
      {getUnavailable()}
    </React.Fragment>)
  return (
    segment ? <Container>
      <Segment {...segmentStyle}>
        {renderForm()}
      </Segment>
    </Container> : centered ? <HorizontalCenteredGrid>
      {renderForm()}
    </HorizontalCenteredGrid> :
    renderForm()
  )
}

export default FormComponent
