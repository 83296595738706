import React, { useState } from 'react'
import {
  Modal, Button, Checkbox,
} from 'semantic-ui-react'
import UnLogGrid from '../../../components/UnLogGrid'

const Login = ({
  history,
  inputs = [],
  sendForm = () => { },
  modal = false,
  forgotPassword = () => { },
  setModal,
  forgotPasswordLang,
  errorTitleLang,
  validLang,
  redirectConfirmLang,
  errorLogin = { validated: true, message: '' },
  noConfirmationMailLang,
  navigateHandle = () => { },
}) => {
  const goToConfirmAccount = () => {
    history.push('/confirmAccount')
  }

  return <div>
      
      <UnLogGrid
      submitMessage={validLang}
      inputs={inputs}
      sendForm={sendForm}
      navigateHandle={navigateHandle}
      // messageLinkeable={{
      //   message: forgotPasswordLang,
      //   onClick: forgotPassword,
      // }}
      // noConfirmationMailLang={{
      //   message: noConfirmationMailLang,
      //   onClick: goToConfirmAccount,
      // }}
    /> 

    <Modal open={modal}
      onClose={() => setModal(false)}
      dimmer="inverted"
      closeIcon
      className="modal-login-container"
    >
      <Modal.Header>{errorTitleLang}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {errorLogin.message}
        </Modal.Description>
      </Modal.Content>

      <Button className="modal-login-button" onClick={errorLogin.validated ? () => setModal(false) : goToConfirmAccount}>{!errorLogin.validated ? redirectConfirmLang : 'Ok'}</Button>
    </Modal>
  </div>
}

export default Login
