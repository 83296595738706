import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import Input from './Input'
// seulement 2 types peuvent être spéciaux: checkbox et radio
export default ({
  next,
  toCheck,
  value,
  onChange,
  ...props
}) => {
  const [nexts, setNexts] = useState(toCheck && next ? [
    { value: next.value, label: next.label },
  ] : [])
  useEffect(() => {
    setNexts(toCheck && next ? [
      { value: next.value, label: next.label },
    ] : [])
    toCheck && next && onChange({
      name:    props.name,
      value:   null,
      checked: toCheck,
      label:   props.label,
      type:    props.type,
    })
  }, [toCheck])

  const isNextEmpty = () => !!nexts.find(({ value }) => !value)

  const adjustValue = value => nexts.filter(e => e)
    .length > 0 ? value + ' ' + nexts.map(
      ({ label, value }) => label + ' ' + value
    ).join(' ') : value

  const handleEffect = ({ value: receivedValue } = {}) => {
    !isNextEmpty() && onChange({
      name:    props.name,
      value:   adjustValue(receivedValue),
      checked: toCheck,
      label:   props.label,
      type:    props.type,
    })
  }

  const handleNextEffect = ({ label, value }) =>
    nexts.find(elem => elem.label === label) ? setNexts(
      nexts.map(elem => elem.label === label ? { ...elem, value } : elem)
    ) :
    setNexts([...nexts, { label, value }].filter(e => e))

  const haveNext = ({
    next, toCheck, value, ...props
  }) => {
    return next ? <Form.Field>
      <Input {...{
        ...props, toCheck, value,
      }} />
      {toCheck && value && haveNext({ ...next, onChange: handleNextEffect })}
    </Form.Field> : <Input {...{
      ...props, toCheck, value,
    }} />
  }

  return haveNext({
    ...props,
    next,
    toCheck,
    value,
    onChange: handleEffect,
  })
}
