import {
  getUser, getParentsPaths, getLoginPathsIndexes,
} from '../helpers'

export const getMenuIndex = pathname => {
  let indexMenu = 0
  const loginPathsIndexes = getLoginPathsIndexes()
  Object.keys(loginPathsIndexes).map(key => {
    if (loginPathsIndexes[key].regex.test(pathname)) {
      const parentsPaths = getParentsPaths()
      indexMenu = Object
        .keys(parentsPaths)
        .map(key => parentsPaths[key])
        .indexOf(loginPathsIndexes[key].parent)
    }
  })
  return indexMenu
}

export const getItemsMenu = ({
  language: {
    menu: {
      dashboard: dashboardLang,
    },
  },
}) => {

  const parentsPaths = getParentsPaths()
  const menuItems = [
    {
      label:    dashboardLang,
      iconLeft: 'columns',
      href:     parentsPaths.home,
    },
  ].filter(e => e)

  return { menuItems }
}

export default {
  getItemsMenu,
  getMenuIndex,
}
