import React from 'react'

const HorizontalCenteredGrid = ({
  children,
}) => {
  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>{children}</div>
  )
}

export default HorizontalCenteredGrid
