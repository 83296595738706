import React, { useState, useContext } from 'react'
import {
  Form,
} from 'semantic-ui-react'
import Checkbox from './CheckBox'
import InlineTextArea from './InlineTextArea'
import InlineSelect from './InlineSelect'
import { Store } from '../store/store'
import KeyWordsInput from './KeyWordsInput'
import TextInput from './TextInput'
import InputPassword from './InputPassword'
import InputEmail from './InputEmail'
export default ({
  onKeyPress = () => { },
  type,
  name,
  onChange,
  value: initialValue,
  label: labelObject,
  styles = { container: '' },
  placeholder,
  required,
  silentRequired = false,
  toCheck = false,
  disabled,
  options = [],
  maxLength = null,
  fluid = false,
  legend = null,
  inline = true,
  responsive = true,
  signIn = false,
  style = {},
  labelStyle = {},
  labelRight = false,
  centered = false,
  info = null,
  placeholders,
  classNameKeyWords,
  error: errorLogic = '',
  preview = false,
}) => {
  const {
    state: {
      languageValue,
      language: {
        form: {
          required: requiredLang,
          file: fileLang,
          fileError: fileErrorLang,
        },
      },
    },
  } = useContext(Store)
  const label = typeof labelObject === 'object' ? labelObject[languageValue] ? labelObject[languageValue] : labelObject : labelObject
  const [error, setError] = useState(required && !initialValue)
  const [value, setValue] = useState(initialValue || '')

  const getNewValue = (eventValue, initialValue, receivedValue) => {
    required && setError(eventValue === '')
    return eventValue || /* initialValue || */ receivedValue
  }

  const handleEffect = (event, { checked, value: receivedValue } = {}) => {
    required && setError(event.target.value === '')
    const value = getNewValue(event.target.value, initialValue, receivedValue)
    setValue(value)
    onChange({
      name,
      value,
      checked,
      label,
      type,
    })
  }
  // implementer next ici pour Radio et checkbox
  return type === 'radio' ? <Form.Radio
    label={label}
    value={initialValue}
    checked={toCheck}
    onChange={handleEffect}
  /> : type === 'checkbox' ? <Checkbox
    value={initialValue}
    disabled={disabled}
    label={label}
    labelRight={labelRight}
    checked={toCheck}
    onChange={handleEffect}
    info={info}
    required={required}
    styles={styles}
    responsive={responsive}
    centered={centered}
    inline={inline}
    labelStyle={labelStyle}
    name={name}
    initialValue={initialValue}

  /> : type === 'textArea' ? <InlineTextArea
    requiredLang={requiredLang}
    id={name + (initialValue ? '-' + initialValue : '')}
    initialValue={initialValue}
    handleEffect={handleEffect}
    name={name}
    value={value || ''}
    placeholder={placeholder}
    onChange={handleEffect}
    onBlur={handleEffect}
    maxLength={maxLength}
    label={label}
    legend={legend}
    required={required}
    error={error}
    silentRequired={silentRequired}
    styles={styles}
    info={info}
  /> : type === 'select' ? <InlineSelect
    requiredLang={requiredLang}
    id={name + (initialValue ? '-' + initialValue : '')}
    initialValue={initialValue}
    handleEffect={handleEffect}
    name={name}
    value={value || ''}
    placeholder={placeholder}
    onChange={handleEffect}
    onBlur={handleEffect}
    maxLength={maxLength}
    label={label}
    legend={legend}
    required={required}
    error={error}
    silentRequired={silentRequired}
    options={options}
    info={info}
  /> : type === 'keyWords' ? <KeyWordsInput
    onKeyPress={onKeyPress}
    type={type}
    name={name}
    onChange={onChange}
    value={value}
    initialValue={initialValue}
    error={error}
    label={label}
    labelObject={labelObject}
    styles={styles}
    placeholder={placeholder}
    required={required}
    silentRequired={silentRequired}
    toCheck={toCheck}
    disabled={disabled}
    options={options}
    maxLength={maxLength}
    fluid={fluid}
    legend={legend}
    inline={inline}
    responsive={responsive}
    style={style}
    labelStyle={labelStyle}
    centered={centered}
    info={info}
    handleEffect={handleEffect}
    requiredLang={requiredLang}
    fileLang={fileLang}
    fileErrorLang={fileErrorLang}
    placeholders={placeholders}
    classNameKeyWords={classNameKeyWords}
  /> : type === 'password' ? <InputPassword
    onKeyPress={onKeyPress}
    name={name}
    onChange={onChange}
    value={value}
    initialValue={initialValue}
    error={error}
    label={label}
    labelObject={labelObject}
    styles={styles}
    placeholder={placeholder}
    required={required}
    silentRequired={silentRequired}
    toCheck={toCheck}
    disabled={disabled}
    options={options}
    maxLength={maxLength}
    fluid={fluid}
    legend={legend}
    inline={inline}
    responsive={responsive}
    style={style}
    labelStyle={labelStyle}
    centered={centered}
    info={info}
    handleEffect={handleEffect}
    requiredLang={requiredLang}
    fileLang={fileLang}
    fileErrorLang={fileErrorLang}
    signIn={signIn}
  /> : type === 'email' ? <InputEmail
    onKeyPress={onKeyPress}
    type={type}
    name={name}
    onChange={onChange}
    value={value}
    initialValue={initialValue}
    error={error}
    label={label}
    labelObject={labelObject}
    styles={styles}
    placeholder={placeholder}
    required={required}
    silentRequired={silentRequired}
    toCheck={toCheck}
    disabled={disabled}
    options={options}
    maxLength={maxLength}
    fluid={fluid}
    legend={legend}
    inline={inline}
    responsive={responsive}
    style={style}
    labelStyle={labelStyle}
    centered={centered}
    info={info}
    handleEffect={handleEffect}
    requiredLang={requiredLang}
    fileLang={fileLang}
    fileErrorLang={fileErrorLang}
  /> : <TextInput
    onKeyPress={onKeyPress}
    type={type}
    name={name}
    onChange={onChange}
    value={value}
    initialValue={initialValue}
    error={error}
    label={label}
    labelObject={labelObject}
    styles={styles}
    placeholder={placeholder}
    required={required}
    silentRequired={silentRequired}
    toCheck={toCheck}
    disabled={disabled}
    options={options}
    maxLength={maxLength}
    fluid={fluid}
    legend={legend}
    inline={inline}
    responsive={responsive}
    style={style}
    labelStyle={labelStyle}
    centered={centered}
    info={info}
    handleEffect={handleEffect}
    requiredLang={requiredLang}
    fileLang={fileLang}
    fileErrorLang={fileErrorLang}
    errorLogic={errorLogic}
    preview={preview}
  />
}
