import languages from '../languages'
const initialUser = {
}

const initialSocket = {
  status:  '',
  message: '',
}

export const initialState = {
  notifications:    [],
  user:             initialUser,
  languageValue:    'us',
  language:         languages.us,
  languageHidden:   false,
  rightSideLoading: true,
  indexMenu:        0,
  menu:             true,
  socket:           initialSocket,
}

const namesReducer = (reducer, name) => {
  const namedReducer = {}
  if (!name) { return reducer }
  Object
    .keys(reducer)
    .map(key => {
      const objectReducer = (state, { payload }) => ({
        ...state,
        [name.toLowerCase()]: reducer[key](state[name.toLowerCase()], { payload }),
      })
      return (
        namedReducer[`${name}/${key}`] = objectReducer
      )
    })
  return namedReducer
}

const APIreducer = {
  RESET:               () => initialState,
  INIT_NOTIFICATIONS:  (state, { payload }) => ({ ...state, notifications: payload }),
  ADD_NOTIFICATION:    (state, { payload }) => ({ ...state, notifications: [...state.notifications, payload] }),
  REMOVE_NOTIFICATION: (state, { payload }) => ({
    ...state,
    notifications: state
      .notifications
      .filter(notification => notification.id !== payload),
  }),
  CHANGE_LANGUAGE: (state, { payload = 'fr' }) => {
    localStorage.setItem('language', payload)
    return ({
      ...state, language:      languages[payload], languageValue: payload,
    })
  },
  SHOW_MENU:         state => ({ ...state, menu: true }),
  HIDE_MENU:         state => ({ ...state, menu: false }),
  HIDE_LANGUAGE:     state => ({ ...state, languageHidden: true }),
  UPDATE_INDEX_MENU: (state, { payload }) => ({ ...state, indexMenu: payload }),
  LOADING_ON:        state => ({ ...state, rightSideLoading: true }),
  LOADING_OFF:       state => ({ ...state, rightSideLoading: false }),

}

const userReducer = {
  INIT:   (state, { payload }) => ({ ...payload }),
  UPDATE: (state, { payload }) => ({ ...state, ...payload }),
  RESET:  state => ({ ...initialUser }),
}

const subscriptionReducer = {
  INIT:    (state, { payload }) => ({ ...payload }),
  PAYMENT: (state, { payload }) => ({ ...state, ...payload }),
  RESET:   () => ({ ...initialSocket }),
}

const reducers = {
  ...APIreducer,
  ...(namesReducer(userReducer, 'USER')),
  ...(namesReducer(subscriptionReducer, 'SOCKET')),
}

const reducer = (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state

export default reducer
