import React, { useReducer } from 'react'
import reducer, { initialState } from './reducers'

const Store = React.createContext()
const Provider = ({ children, initialValues }) => {
  const [state, dispatch] = useReducer(reducer, initialValues || initialState)
  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
}

export { Store, Provider }
