export const FR = {
  nextButton: 'Étape suivante',
}

export const EN = {
  nextButton: 'Next step',
}

export default {
  fr: FR,
  us: EN,
}
