import React from 'react'
import { Icon, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const Notification = ({
  title = 'Hello World',
  message = 'loripsum al nafreti com discolarion, te que pame olda foret yaue.',
  type = 'missed',
  id,
  link = null,
  remove = () => { },
}) => {
  const getType = () => {
    switch (type) {
    case 'startCampaign':
      return 'in-progress'
    case 'error':
      return 'error'
    case 'waiting':
      return 'waiting'
    default:
      return 'done'
    }
  }

  const removeNotification = () => remove(id)

  const renderNotificationContent = () => <div className="bottom-container" onClick={removeNotification}>
    <div className="ta-center">
      <Header as="h4" className="color-white">{title}</Header>
    </div>
    <div className="message color-white mt-5px sub-legend">{message}</div>
  </div>

  const linkTreatment = () => link ? <Link to={link}>{renderNotificationContent()}</Link> : renderNotificationContent()

  return <div className={`notification-container ${getType(type)}`}>
    <div className="top-container">
      <Icon size="small" name='bell' color="red" />
      <Icon size="small" name='remove' color="black" onClick={removeNotification} />
    </div>
    {linkTreatment()}
  </div>
}

export default Notification
