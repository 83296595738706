import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import NotificationContainer from './NotificationContainer'
import { Link } from 'react-router-dom'

const MenuComponent = ({
  menuItems = [],
  lastItem = {},
  indexMenu,
}) => {
  const getItem = ({
    label, href = '/', separator, iconLeft, iconRight, className = '',
  }, index) => <Link to={href} key={index}>
    <div className={`pointer white mb-item item-menu ${className}` + (index === indexMenu ? ' active' : '')}>
      {iconLeft && <Icon className="icon-item left" name={iconLeft} />}
      <span>{label}</span>
      {iconRight && <Icon className="icon-item right" name={iconRight} />}
    </div>
    {separator && <div className="mb-item separator-items white" />}
  </Link>

  return (
    <Grid className="left-container-home max-height" >
      <Grid.Column className="padding-0 menu-container">
        <div className="ml-15-i menu-items">{menuItems.map(getItem)}</div>
        <NotificationContainer />
        <div className="ml-15-i mt-23px menu-items">{getItem(lastItem, lastItem.index)}</div>
      </Grid.Column>
    </Grid>
  )
}

export default MenuComponent
